@import "core/index.scss";
.page.error {
	.error-container {
		position: relative;
	}
	
	.error-bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;

		@include media {
			object-position: top center;
		}
	}

	.error-innerwrap {
		position: relative;
		z-index: 2;
		min-height: 70rem;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		@include media {
			justify-content: flex-start;
			min-height: auto;
			padding: 33rem 0 4rem;
		}
	}

	// .error-text {}

		.text-code {
			font-size: 10rem;
			margin-bottom: 2rem;

			@include media {
				font-size: 5rem;
			}
		}

		.text-title {
			@include font-bold;
			font-size: 5rem;

			@include media {
				font-size: 3rem;
			}
		}

		.text-message {
			margin-top: 2.4rem;
			font-size: 2rem;
			line-height: 1.2em;

			@include media {
				font-size: 1.6rem;
				margin-top: 1.2rem;
			}

			a {
				color: $color-pink;
				text-decoration: underline;
			}
		}
}